<template>
<!--  <Panel header="Consumables statistics" :toggleable="true" :collapsed="isCollapsed">-->
<!--    <template v-if="consumablesStat?.length">-->
<!--      <div class="datatable-wrapper">-->
<!--        <table class="p-datatable p-datatable-striped p-datatable-sm p-datatable-hoverable-rows positions-table">-->
<!--          <thead class="p-datatable-tbody">-->
<!--            <tr class="table-opened-info__header-row">-->
<!--              <th>Purchased</th>-->
<!--              <th>Sold</th>-->
<!--              <th>Profit</th>-->
<!--            </tr>-->
<!--          </thead>-->
<!--          <tbody class="p-datatable-tbody">-->
<!--            <tr>-->
<!--              <td>Purchase invoices</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td>Purchase invoices</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td class="no-bottom-border">Sales invoices</td>-->
<!--            </tr>-->
<!--          </tbody>-->
<!--        </table>-->
<!--      </div>-->
<!--    </template>-->
<!--    <div v-else style="text-align: center; padding: 35px;">-->
<!--      {{ $t('No data available' )}}-->
<!--    </div>-->
<!--  </Panel>-->

  <Panel header="Consumables statistics" :toggleable="true" :collapsed="isCollapsed">
    <div class="datatable-wrapper" v-if="consumablesStat">
      <table class="p-datatable p-datatable-striped p-datatable-sm p-datatable-hoverable-rows positions-table">
        <thead class="p-datatable-tbody">
        <!--        <thead class="p-datatable-thead">-->
        <tr class="table-opened-info__header-row">
          <th>Purchased</th>
          <th>Sold</th>
          <th>Profit</th>
        </tr>
        </thead>
        <tbody class="p-datatable-tbody">
          <tr>
            <td class="no-bottom-border">{{ formatCurrency(consumablesStat.purchased) }}</td>
            <td class="no-bottom-border">{{ formatCurrency(consumablesStat.sold) }}</td>
            <td class="no-bottom-border">{{ formatCurrency(consumablesStat.sold - consumablesStat.purchased) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--    <div v-else style="text-align: center; padding: 35px;">-->
    <!--      {{ $t('No data available' )}}-->
    <!--    </div>-->
  </Panel>
</template>

<script>
import formatMixins from "@/mixins/formatMixins";
export default {
  mixins: [ formatMixins ],
  name: 'ConsumablesPanel',
  props: {
    consumablesStat: {
      type: Object,
    }
  },
  data: () => {
    return {
      isCollapsed: false,
    }
  },
}
</script>
<template>
  <Panel header="Payments" :toggleable="true" :collapsed="isCollapsed">
    <div class="datatable-wrapper" v-if="payments">
      <table class="p-datatable p-datatable-striped p-datatable-sm p-datatable-hoverable-rows positions-table">
        <thead class="p-datatable-tbody">
<!--        <thead class="p-datatable-thead">-->
          <tr class="table-opened-info__header-row">
            <th style="width: 40%">Type</th>
            <th style="width: 20%">Transactions</th>
            <th style="width: 20%">Total sum</th>
            <th style="width: 20%">Percent %</th>
          </tr>
        </thead>
        <tbody class="p-datatable-tbody">
          <tr>
            <td>Debit/credit cards</td>
            <td>{{ payments.card.count ?? 0 }}</td>
            <td>{{ formatCurrency(payments.card.sum) }}</td>
            <td>{{ calculatePercent(payments.card.sum) }}%</td>
          </tr>
          <tr>
            <td>Cash payments</td>
            <td>{{ payments.cash.count ?? 0 }}</td>
            <td>{{ formatCurrency(payments.cash.sum) }}</td>
            <td>{{ calculatePercent(payments.cash.sum) }}%</td>
          </tr>
          <tr>
            <td>Bank transfers</td>
            <td>{{ payments.bank.count ?? 0 }}</td>
            <td>{{ formatCurrency(payments.bank.sum) }}</td>
            <td>{{ calculatePercent(payments.bank.sum) }}%</td>
          </tr>
          <tr class="table-opened-info__row--total">
            <td class="no-bottom-border">Total</td>
            <td class="no-bottom-border">{{ payments.total_count ?? 0 }}</td>
            <td class="no-bottom-border">{{ formatCurrency(payments.total_sum) }}</td>
            <td class="no-bottom-border"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </Panel>
</template>

<script>
import formatMixins from "@/mixins/formatMixins";
export default {
  mixins: [ formatMixins ],
  name: 'PaymentsPanel',
  props: {
    payments: {
      type: Object,
    }
  },
  data: () => {
    return {
      isCollapsed: false,
    }
  },
  methods: {
    calculatePercent(sum) {
      if (!this.payments.total_sum || !sum) return 0

      const percent = sum * 100/this.payments.total_sum
      return percent.toFixed(2)
    }
  }
}
</script>

<!--<style lang="scss">-->
<!--.datatable-wrapper {-->
<!--  overflow-y: auto;-->
<!--}-->

<!--.positions-table {-->
<!--  //margin-bottom: 67px;-->
<!--  border-collapse: collapse;-->
<!--  width: 100%;-->
<!--  text-align: left;-->
<!--  tr {-->
<!--    th {-->
<!--      padding: 8px !important;-->
<!--    }-->
<!--    td {-->
<!--      padding: 8px !important;-->
<!--    }-->
<!--  }-->
<!--}-->

<!--.table-opened-info__row&#45;&#45;total {-->
<!--  font-weight: 500;-->
<!--}-->

<!--.no-bottom-border {-->
<!--  border-bottom: none !important;-->
<!--}-->
<!--</style>-->
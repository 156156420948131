<template>
  <Spinner v-if="isLoading" :isSmall="true" :spinnerStyle="'margin: 20px auto'"></Spinner>
  <div class="datatable-wrapper" v-else-if="!isLoading && positions?.length">
<!--    :class="{ 'datatable&#45;&#45;small-text': isSpoilerTable }"-->
    <table class="p-datatable p-datatable-striped p-datatable-sm p-datatable-hoverable-rows positions-table"
           :class="{ 'datatable&#45;&#45;small-text': isSpoilerTable }">
      <thead class="p-datatable-tbody"
             :class="{ 'p-datatable-thead' : !isSpoilerTable }">
        <tr class="table-opened-info__header-row">
          <th>{{ $t('Supplier') }}</th>
          <th>{{ $t('Code') }}</th>
          <th>{{ $t('Name') }}</th>
<!--          <th>Name</th>-->
          <th>{{ $t('Qty') }}</th>
          <th>Buy</th>
          <th>Sell</th>
          <th>Dis.</th>
          <th>Sum</th>
          <th>Tax</th>
          <th>Total</th>
          <th>Margin</th>
          <th>Profit</th>
          <th style="width: 30px"></th>
<!--          <th style="width: 3%"></th>-->
<!--          <th style="width: 3%"></th>-->
        </tr>
      </thead>
      <tbody class="p-datatable-tbody">
<!--        <tr class="table-opened-info__row" v-for="(position, index) of computedActualPositions" :key="index">-->
        <tr v-for="(position, index) of positions" :key="index">
  <!--        <template v-if="position.status === 1">-->
          <td>
            <span v-if="position.type === 1 && position.master">{{ position.master.first_name }} {{ position.master.last_name }}</span>
            <span v-else-if="position.type === 2 && position.supplier">{{ position.supplier.company_name }}</span>
            <i v-else class="ti-minus"></i>
          </td>
          <td>
            <span v-if="position.type === 1 && position.service?.code">{{ position.service.code }}</span>
            <span v-else-if="position.code">{{ position.code }}</span>
            <i v-else class="ti-minus"></i>
          </td>
          <td>{{ position.name }}</td>
          <td>{{ position.qty }}</td>
          <td>{{ formatCurrency(position.purchase_price) }}</td>
          <td>{{ formatCurrency(position.sell_price) }}</td>
          <td>
            <span v-if="position.discount && +position.discount">{{ formatDecimal(position.discount) }}%</span>
            <i v-else class="ti-minus"></i>
          </td>
          <td>{{ formatCurrency(((position.sell_price - (((position.discount ?? 0) / 100) * position.sell_price)) * position.qty)) }}</td>
          <td><span v-if="position.tax?.value">{{ position.tax.value }}%</span></td>
          <td>{{ formatCurrency(((position.sell_price - (((position.discount ?? 0) / 100) * position.sell_price)) * position.qty) * (((position.tax ? position.tax.value : 0) / 100) + 1)) }}</td>
          <td>{{ calculatePositionMargin(position) }}</td>
          <td>{{ formatCurrency(calculatePositionProfit(position)) }}</td>
          <td>
            <span v-if="position.order?.number">
              <router-link :to="{ path: `/orders/${position.order.number}` }" target="_blank"><i class="ti-hummer"></i></router-link>
            </span>
            <span v-else>-</span>
          </td>
<!--          <td class="data-custom-padding">-->
<!--            <RequestPositionStateButton :isDisabled="item.state === 12 && $store.state.user.role !== constants.userRoles.superAdmin && $store.state.user.role !== constants.userRoles.admin" :orderId="item.id" :orderIsArchived="!!item.is_archived" :position="position" @update-items="updateItems"/>-->
<!--          </td>-->
<!--          <td class="data-custom-padding">-->
<!--            <PositionHistoryButton :position="position"/>-->
<!--          </td>-->
          <!--                            <td><a @click.prevent href="#" class="p-link table-link-icon"><i class="ti-link"></i></a></td>-->
<!--          <td class="data-custom-padding">-->
<!--            <PositionFixedQtyButton :position="position"/>-->
<!--          </td>-->
        </tr>
      </tbody>
    </table>
  </div>
  <div v-else :class="{ 'datatable--small-text': isSpoilerTable }" style="text-align: center; padding: 35px;">
    {{ $t('No data available' )}}
  </div>
</template>

<script>
// import constants from '@/constants'
import formatMixins from '@/mixins/formatMixins'

export default {
  emits: [ 'update-items' ],
  mixins: [ formatMixins ],
  name: 'ManagerSalesPositionsTable',
  props: {
    isLoading: Boolean,
    positions: Array,
    // showDeletedPositions: {
    //   type: Boolean,
    //   default: false
    // },
    // item: Object,
    isSpoilerTable: {
      type: Boolean,
      default: true
    }
    // colspan: Number,
  },
  // data() {
  //   return {
  //     constants
  //   }
  // },
  methods: {
    calculatePositionProfit(position) {
      if (!position) return 0
      return (position.sum ?? 0) - (position.purchase_sum ?? 0)
    },
    // updateItems(item) {
    //   this.$emit('update-items', item)
    // },
    calculatePositionMargin(position) {
      // If calculate one/single position
      // const margin = (((position.sell_price - (((position.discount ?? 0) / 100) * position.sell_price)) * 100) / (position.purchase_price ?? 0)) - 100

      // If calculate all positions
      const margin = ((position.sum * 100) / position.purchase_sum) - 100
      if (+margin && isFinite(+margin)) {
        return this.formatDecimal(margin) + '%'
      } else {
        return
      }
    },
    toggleRecommendationHistoryOverlay(event, id) {
      if (!event || !id) {
        return false
      }
      this.$refs['rh' + id.toString()].toggle(event);
    },
    toggleCustomerCommentsHistoryOverlay(event, id) {
      if (!event || !id) {
        return false
      }
      this.$refs['ch' + id.toString()].toggle(event);
    },
    toggleStaffCommentsHistoryOverlay(event, id) {
      if (!event || !id) {
        return false
      }
      this.$refs['sh' + id.toString()].toggle(event);
    },
  },
  computed: {
    computePurchaseSum() {
      if (!this.item || !this.activePosition) return 0
      const positionsPurchasePrice = this.activePosition.reduce((sum, position) => sum + +position.purchase_sum, 0)
      // const consumablePurchasePrice = this.item.consumable ? (+this.item.consumable.purchase_price ?? 0) : 0
      // return positionsPurchasePrice + consumablePurchasePrice
      return positionsPurchasePrice
    },
    computeSellPrice() {
      if (!this.item || !this.activePosition) return 0
      const positionsSellPrice = this.activePosition.reduce((sum, position) => sum + +position.sell_price * position.qty, 0)
      const consumableSellPrice = this.item.consumable ? (+this.item.consumable.sell_price ?? 0) : 0
      return positionsSellPrice + consumableSellPrice
    },
    computeDiscountSum() {
      if (!this.item || !this.activePosition) return 0

      const positionsDiscountSum = this.activePosition.reduce((sum, position) => sum + +position.discount_sum, 0)
      const consumableDiscountSum = this.item.consumable ? (+this.item.consumable.discount_sum ?? 0) : 0
      return positionsDiscountSum + consumableDiscountSum
    },
    computeSum() {
      if (!this.item || !this.activePosition) return 0

      const positionsDiscountSum = this.activePosition.reduce((sum, position) => sum + +position.sum, 0)
      const consumableDiscountSum = this.item.consumable ? (+this.item.consumable.sum ?? 0) : 0
      return positionsDiscountSum + consumableDiscountSum
    },
    computeTotalSum() {
      if (!this.item || !this.activePosition) return 0

      const positionsDiscountSum = this.activePosition.reduce((sum, position) => sum + +position.total, 0)
      const consumableDiscountSum = this.item.consumable ? (+this.item.consumable.total ?? 0) : 0
      return positionsDiscountSum + consumableDiscountSum
    },
    computeTotalProfit() {
      if (!this.item || !this.activePosition) return 0

      const positionsPurchaseSum = this.activePosition.reduce((sum, position) => sum + +position.purchase_sum, 0)
      const positionsSum = this.activePosition.reduce((sum, position) => sum + +position.sum, 0)
      const positionsProfit = positionsSum - positionsPurchaseSum

      // const consumableProfit = this.item.consumable ? ((+this.item.consumable.sum ?? 0) - (+this.item.consumable.purchase_price ?? 0)) : 0

      return positionsProfit

      // .reduce((sum, position) => sum + ((position.sell_price - (((position.discount || 0) / 100) * position.sell_price)) * position.qty) - (position.purchase_price * position.qty), 0) + (item.consumable ? (((item.consumable.sell_price - (((item.consumable.discount || 0) / 100) * item.consumable.sell_price)) * item.consumable.qty) - (item.consumable.purchase_price * item.consumable.qty)) : 0))
    },
    noPositions() {
      if (this.positions?.length) {
        return !this.positions.find(position => position.status === 1)
      } else {
        return false
      }
    },
  }
}
</script>

<style scoped lang="scss">
.datatable-wrapper {
  overflow-y: auto;
//  background-color: inherit!important;
//  //padding-bottom: 25px;
//  &:hover {
//    background-color: inherit!important;
//  }
}

.positions-table {
  //margin-bottom: 5px;
  border-collapse: collapse;
  //overflow-y: auto!important;
  width: 100%;
  tr {
    .small-width {
      width: 6%;
    }
    .medium-width {
      width: 10%;
    }
    th {
      padding: 10px 7px;

    }
    td {
      padding: 7px;
      height: 45px;
      //background-color: inherit !important;
    }
  }
}
.data-custom-padding {
  padding: 3px !important;
}

.datatable--small-text {
  font-size: 0.92em;
}

//.table-opened-info__row {
  //background-color: red!important;
//}

.comment {
}

.customer-comment {
  margin: 0 2px 4px 0 !important;
}
.staff-comment {
  margin: 0 0 4px 2px !important;
}
.recommendations {
  margin: 0 0 4px 0 !important;
}

//.table-opened-info__row--total {
//  background-color: green!important;
//  padding-bottom: 20px!important;
//  margin-bottom: 100px!important;
//}

.no-bottom-border {
  border-bottom: none !important;
}
</style>
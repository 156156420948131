<template>
  <Panel header="Invoices" :toggleable="true" :collapsed="isCollapsed">
    <div class="datatable-wrapper" v-if="invoices">
      <table class="p-datatable p-datatable-striped p-datatable-sm p-datatable-hoverable-rows positions-table">
        <thead class="p-datatable-tbody">
        <!--        <thead class="p-datatable-thead">-->
            <tr class="table-opened-info__header-row">
              <th>Type</th>
              <th>Count</th>
              <th>Sum</th>
              <th>Tax</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody class="p-datatable-tbody">
            <tr>
              <td>Purchase invoices</td>
              <td>{{ invoices.purchase.count }}</td>
              <td>{{ formatCurrency(invoices.purchase.sum) }}</td>
              <td>{{ formatCurrency(invoices.purchase.tax) }}</td>
              <td>{{ formatCurrency(invoices.purchase.total) }}</td>
            </tr>
            <tr>
              <td class="no-bottom-border">Sales invoices</td>
              <td class="no-bottom-border">{{ invoices.sales.count }}</td>
              <td class="no-bottom-border">{{ formatCurrency(invoices.sales.sum) }}</td>
              <td class="no-bottom-border">{{ formatCurrency(invoices.sales.tax) }}</td>
              <td class="no-bottom-border">{{ formatCurrency(invoices.sales.total) }}</td>
            </tr>
        </tbody>
      </table>
    </div>
<!--    <div v-else style="text-align: center; padding: 35px;">-->
<!--      {{ $t('No data available' )}}-->
<!--    </div>-->
  </Panel>
</template>

<script>
import formatMixins from "@/mixins/formatMixins";
export default {
  mixins: [ formatMixins ],
  name: 'InvoicesPanel',
  props: {
    invoices: {
      type: Object,
    }
  },
  data: () => {
    return {
      isCollapsed: false,
    }
  },
}
</script>
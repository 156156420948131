<template>
  <Panel header="Positions sales" :toggleable="true" :collapsed="isCollapsed">
    <template v-if="!objectIsEmpty">
        <table class="p-datatable p-datatable-striped p-datatable-sm p-datatable-hoverable-rows positions-table">
          <thead class="p-datatable-tbody">
            <tr class="table-opened-info__header-row">
              <th style="width: 18%"></th>
              <th style="width: 10%">Count</th>
              <th style="width: 12%">Purchase price</th>
              <th style="width: 12%">Sell price</th>
              <th style="width: 12%">Tax sum</th>
              <th style="width: 12%">Discount sum</th>
              <th style="width: 12%">Profit</th>
<!--              <th style="width: 12%">Margin</th>-->
            </tr>
          </thead>
          <tbody class="p-datatable-tbody">
            <tr v-if="managerPositions.services?.count">
              <td>Services</td>
              <td>{{ managerPositions.services?.count }}</td>
              <td>{{ formatCurrency(managerPositions.services?.purchase_sum) }}</td>
              <td>{{ formatCurrency(managerPositions.services?.sell_price) }}</td>
              <td>{{ formatCurrency(managerPositions.services?.tax_sum) }}</td>
              <td>{{ formatCurrency(managerPositions.services?.discount_sum) }}</td>
              <td>{{ formatCurrency(managerPositions.services?.sell_price - managerPositions.services?.purchase_sum) }}</td>
<!--              <td>{{ calculateMargin(managerPositions.services) }}</td>-->
            </tr>
            <tr v-if="managerPositions.requests?.count">
              <td>Requests</td>
              <td>{{ managerPositions.requests?.count }}</td>
              <td>{{ formatCurrency(managerPositions.requests?.purchase_sum) }}</td>
              <td>{{ formatCurrency(managerPositions.requests?.sell_price) }}</td>
              <td>{{ formatCurrency(managerPositions.requests?.tax_sum) }}</td>
              <td>{{ formatCurrency(managerPositions.requests?.discount_sum) }}</td>
              <td>{{ formatCurrency(managerPositions.requests?.sell_price - managerPositions.requests?.purchase_sum) }}</td>
<!--              <td>{{ calculateMargin(managerPositions.requests) }}</td>-->
            </tr>
            <tr v-if="managerPositions.warehouse_positions?.count">
              <td>Warehouse items</td>
              <td>{{ managerPositions.warehouse_positions?.count }}</td>
              <td>{{ formatCurrency(managerPositions.warehouse_positions?.purchase_sum) }}</td>
              <td>{{ formatCurrency(managerPositions.warehouse_positions?.sell_price) }}</td>
              <td>{{ formatCurrency(managerPositions.warehouse_positions?.tax_sum) }}</td>
              <td>{{ formatCurrency(managerPositions.warehouse_positions?.discount_sum) }}</td>
              <td>{{ formatCurrency(managerPositions.warehouse_positions?.sell_price - managerPositions.warehouse_positions?.purchase_sum) }}</td>
<!--              <td>{{ calculateMargin(managerPositions.warehouse_positions) }}</td>-->
            </tr>
            <tr v-if="managerPositions.free_positions?.count">
              <td>Free positions</td>
              <td>{{ managerPositions.free_positions?.count }}</td>
              <td>{{ formatCurrency(managerPositions.free_positions?.purchase_sum) }}</td>
              <td>{{ formatCurrency(managerPositions.free_positions?.sell_price) }}</td>
              <td>{{ formatCurrency(managerPositions.free_positions?.tax_sum) }}</td>
              <td>{{ formatCurrency(managerPositions.free_positions?.discount_sum) }}</td>
              <td>{{ formatCurrency(managerPositions.free_positions?.sell_price - managerPositions.free_positions?.purchase_sum) }}</td>
<!--              <td>{{ calculateMargin(managerPositions.free_positions) }}</td>-->
            </tr>
<!--            <tr v-if="managerPositions.consumables?.count">-->
<!--              <td>Consumables</td>-->
<!--              <td>{{ managerPositions.consumables?.count }}</td>-->
<!--              <td><i class="ti-minus"></i></td>-->
<!--              <td>{{ formatCurrency(managerPositions.consumables?.sell_price) }}</td>-->
<!--              <td>{{ formatCurrency(managerPositions.consumables?.tax_sum) }}</td>-->
<!--              <td>{{ formatCurrency(managerPositions.consumables?.discount_sum) }}</td>-->
<!--              <td><i class="ti-minus"></i></td>-->
<!--              <td><i class="ti-minus"></i></td>-->
<!--            </tr>-->
            <tr class="table-opened-info__row--total">
              <td class="no-bottom-border">Total</td>
              <td class="no-bottom-border">{{ calculateCount() }}</td>
              <td class="no-bottom-border">{{ formatCurrency(calculatePurchaseSum()) }}</td>
              <td class="no-bottom-border">{{ formatCurrency(calculateSellSum()) }}</td>
              <td class="no-bottom-border">{{ formatCurrency(calculateTaxSum()) }}</td>
              <td class="no-bottom-border">{{ formatCurrency(calculateTotalDiscountSum()) }}</td>
              <td class="no-bottom-border">{{ formatCurrency(calculateTotalProfit()) }}</td>
<!--              <td class="no-bottom-border"></td>-->
            </tr>
          </tbody>
      </table>
    </template>
    <div v-else style="text-align: center; padding: 35px;">
      {{ $t('No data available' )}}
    </div>
  </Panel>
</template>

<script>
import formatMixins from "@/mixins/formatMixins";
export default {
  mixins: [ formatMixins ],
  name: 'ManagerSalesPanel',
  props: {
    managerPositions: {
      type: Object,
    }
  },
  data: () => {
    return {
      isCollapsed: false,
    }
  },
  methods: {
    calculateCount() {
      // if (!manager) return 0
      const servicesCount = this.managerPositions.services?.count ?? 0
      const requestsCount = this.managerPositions.requests?.count ?? 0
      const warehousePositionsCount = this.managerPositions.warehouse_positions?.count ?? 0
      const freePositionsCount = this.managerPositions.free_positions?.count ?? 0
      // const consumablesCount = this.managerPositions.consumables?.count ?? 0
      return +servicesCount + +requestsCount + +warehousePositionsCount + +freePositionsCount
    },
    calculatePurchaseSum() {
      const servicesPurchaseSum = this.managerPositions.services?.purchase_sum ?? 0
      const requestsPurchaseSum = this.managerPositions.requests?.purchase_sum ?? 0
      const warehousePositionsPurchaseSum = this.managerPositions.warehouse_positions?.purchase_sum ?? 0
      const freePositionsPurchaseSum = this.managerPositions.free_positions?.purchase_sum ?? 0
      // const consumablesPurchaseSum = this.managerPositions.consumables?.purchase_sum ?? 0
      return +servicesPurchaseSum + +requestsPurchaseSum + +warehousePositionsPurchaseSum + +freePositionsPurchaseSum
    },
    calculateSellSum() {
      const servicesSellSum = this.managerPositions.services?.sell_price ?? 0
      const requestsSellSum = this.managerPositions.requests?.sell_price ?? 0
      const warehousePositionsSellSum = this.managerPositions.warehouse_positions?.sell_price ?? 0
      const freePositionsSellSum = this.managerPositions.free_positions?.sell_price ?? 0
      // const consumablesSellSum = this.managerPositions.consumables?.sell_price ?? 0
      return +servicesSellSum + +requestsSellSum + +warehousePositionsSellSum + +freePositionsSellSum
    },
    calculateTaxSum() {
      const servicesTaxSum = this.managerPositions.services?.tax_sum ?? 0
      const requestsTaxSum = this.managerPositions.requests?.tax_sum ?? 0
      const warehousePositionsTaxSum = this.managerPositions.warehouse_positions?.tax_sum ?? 0
      const freePositionsTaxSum = this.managerPositions.free_positions?.tax_sum ?? 0
      // const consumablesTaxSum = this.managerPositions.consumables?.tax_sum ?? 0
      return +servicesTaxSum + +requestsTaxSum + +warehousePositionsTaxSum + +freePositionsTaxSum
    },
    calculateTotalDiscountSum() {
      const servicesDiscountSum = this.managerPositions.services?.discount_sum ?? 0
      const requestsDiscountSum = this.managerPositions.requests?.discount_sum ?? 0
      const warehousePositionsDiscountSum = this.managerPositions.warehouse_positions?.discount_sum ?? 0
      const freePositionsDiscountSum = this.managerPositions.free_positions?.discount_sum ?? 0
      // const consumablesDiscountSum = this.managerPositions.consumables?.discount_sum ?? 0
      return +servicesDiscountSum + +requestsDiscountSum + +warehousePositionsDiscountSum + +freePositionsDiscountSum
    },
    calculateTotalProfit() {
      let profit = 0
      if (this.managerPositions.services) {
        // profit += +manager.services.sell_price - +manager.services.purchase_sum - +manager.services.discount_sum
        profit += +this.managerPositions.services.sell_price - +this.managerPositions.services.purchase_sum
      }

      if (this.managerPositions.requests) {
        // profit += +manager.requests.sell_price - +manager.requests.purchase_sum - +manager.requests.discount_sum
        profit += +this.managerPositions.requests.sell_price - +this.managerPositions.requests.purchase_sum
      }

      if (this.managerPositions.warehouse_positions) {
        // profit += +manager.warehouse_positions.sell_price - +manager.warehouse_positions.purchase_sum - +manager.warehouse_positions.discount_sum
        profit += +this.managerPositions.warehouse_positions.sell_price - +this.managerPositions.warehouse_positions.purchase_sum
      }

      if (this.managerPositions.free_positions) {
        // profit += +manager.free_positions.sell_price - +manager.free_positions.purchase_sum - +manager.free_positions.discount_sum
        profit += +this.managerPositions.free_positions.sell_price - +this.managerPositions.free_positions.purchase_sum
      }

      // if (manager.consumables) {
      //   profit += +manager.consumables.sell_price - +manager.consumables.purchase_sum
      // }

      return profit
    },
  },
  computed: {
    objectIsEmpty() {
      if (!this.managerPositions) return true
      return !Object.keys(this.managerPositions).length
    }
  }

}
</script>

<style scoped lang="scss">
</style>